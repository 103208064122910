import logo from "./logo.svg";
import "./App.css";
import React, { useEffect, useState, lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setUserDataAfterLogin,
  localstorage_TokenAdd,
  setUserType,
} from "./Store/Reducers/CommonReducer";
// js
//import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import ScrollToTop from "./component/ScrollToTop.js";
/*import Layout from './pages/Layout/Layout';
import Home from "./pages/Home/Home"
import About from './pages/About/About';
import MyAccount from './pages/MyAccount/MyAccount';
import TermsCondition from './pages/TermsCondition/TermsCondition';
import RefundPolicy from './pages/RefundPolicy/RefundPolicy';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import Contact from './pages/Contact/Contact';*/

import GridLoader from "react-spinners/GridLoader";

const Layout = lazy(() => import("./pages/Layout/Layout"));
const Home = lazy(() => import("./pages/Home/Home"));
const About = lazy(() => import("./pages/About/About"));
const MyAccount = lazy(() => import("./pages/MyAccount/MyAccount"));
const TermsCondition = lazy(() =>
  import("./pages/TermsCondition/TermsCondition")
);
const RefundPolicy = lazy(() => import("./pages/RefundPolicy/RefundPolicy"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy/PrivacyPolicy"));
const Contact = lazy(() => import("./pages/Contact/Contact"));
const SubSuccess = lazy(() => import("./pages/SubSuccess/SubSuccess"));
const SubProcesses = lazy(() => import("./pages/SubProcesses/SubProcesses"));
const SubFail = lazy(() => import("./pages/SubFail/SubFail"));
const Faq = lazy(() => import("./pages/Faq/Faq"));
const AllCategory = lazy(() => import("./pages/AllCategory/AllCategory"));
const FetchOperator = lazy(() =>
  import("./pages/FetchOperatorBasedOnCategory/FetchOperator")
);
const ImSuccess = lazy(() => import("./pages/ImSuccess/ImSuccess"));
const ImProcesses = lazy(() => import("./pages/ImProcesses/ImProcesses"));
const ImFail = lazy(() => import("./pages/ImFail/ImFail"));

const ImDTHSuccess = lazy(() => import("./pages/ImSuccess/dth/ImDTHSuccess"));
const ImDTHProcesses = lazy(() =>
  import("./pages/ImProcesses/dth/ImDTHProcesses")
);
const ImDTHFail = lazy(() => import("./pages/ImFail/dth/ImDTHFail"));

const ImBILLSuccess = lazy(() =>
  import("./pages/ImSuccess/bill/ImBILLSuccess")
);
const ImBILLProcesses = lazy(() =>
  import("./pages/ImProcesses/bill/ImBILLProcesses")
);
const ImBILLFail = lazy(() => import("./pages/ImFail/bill/ImBILLFail"));

const DeleteRequest = lazy(() =>
  import("./pages/AccountDeleteRequest/DeleteRequest")
);

function App() {
  const dispatch = useDispatch();
  const { userType, userData } = useSelector((state) => state.common);
  let [color, setColor] = useState("#2CBFEF");
  const CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };
  const override = CSSProperties;

  useEffect(() => {
    if (sessionStorage.getItem("userId") != null) {
      dispatch(
        setUserDataAfterLogin(JSON.parse(sessionStorage.getItem("userData")))
      );
      dispatch(localstorage_TokenAdd(sessionStorage.getItem("userToken")));
    }
  }, []);

  return (
    <BrowserRouter>
      <ScrollToTop>
        <Suspense
          fallback={
            <div className="activityLoader">
              <GridLoader
                color={color}
                loading={true}
                cssOverride={override}
                size={30}
                aria-label="Loading Spinner"
                data-testid="loader"
                Loader="GridLoader"
              />
            </div>
          }
        >
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route path="/" element={<Home />} />
              <Route path="about-us" element={<About />} />
              <Route path="myaccount" element={<MyAccount />} />
              <Route path="tearmcondition" element={<TermsCondition />} />
              <Route path="refundpolicy" element={<RefundPolicy />} />
              <Route path="privacypolicy" element={<PrivacyPolicy />} />
              <Route path="contact-us" element={<Contact />} />
              <Route path="faq" element={<Faq />} />
              <Route path="subscription-success" element={<SubSuccess />} />
              <Route path="sub-processes" element={<SubProcesses />} />
              <Route path="subscription-fail" element={<SubFail />} />
              <Route path="categories" element={<AllCategory />} />
              <Route path="operator" element={<FetchOperator />} />
              <Route path="im-success" element={<ImSuccess />} />
              <Route path="im-processes" element={<ImProcesses />} />
              <Route path="im-fail" element={<ImFail />} />
              <Route path="im-dth-success" element={<ImDTHSuccess />} />
              <Route path="im-dth-processes" element={<ImDTHProcesses />} />
              <Route path="im-dth-fail" element={<ImDTHFail />} />
              <Route path="im-bill-success" element={<ImBILLSuccess />} />
              <Route path="im-bill-processes" element={<ImBILLProcesses />} />
              <Route path="im-bill-fail" element={<ImBILLFail />} />
              <Route
                path="account-delete-request"
                element={<DeleteRequest />}
              />
            </Route>
          </Routes>
        </Suspense>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;
